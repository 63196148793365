import withCustomErrorBoundary from '@components/HOC/withCustomErrorBoundary';
import DefaultLayout from '@components/layouts/DefaultLayout';
import getPageErrorProps from '@utils/getPageErrorProps';
import getServerSideTranslations from '@utils/getServerSideTranslations';
import NewOrderCreatePage from '@views/NewOrder/NewOrderCreatePage/NewOrderCreatePage.container';

export const getServerSideProps = async ({ locale }) => {
  try {
    return {
      props: {
        ...(await getServerSideTranslations(locale)),
        bodyClassName: 'page--new-order',
        locale,
      },
    };
  } catch (error) {
    return await getPageErrorProps({ error, locale });
  }
};

const LayoutWithProps = ({ children }) => {
  const styles = { content: { mode: 'replace', css: null } };

  return <DefaultLayout styles={styles}>{children}</DefaultLayout>;
};

const ComponentWithHoc = withCustomErrorBoundary(NewOrderCreatePage);
ComponentWithHoc.Layout = LayoutWithProps;

export default ComponentWithHoc;
