import { useTranslation } from 'next-i18next';

import { useFormikContext } from 'formik';
import tw from 'twin.macro';

import Alert from '@components/elements/Alert';
import Button from '@components/elements/Button';
import InputsIndex from '@components/elements/Form/InputsIndex';
import INPUT_IDS from '@constants/inputs/registerInputs';

import useRegisterFields from './useRegisterFields';

const RegisterFormFields = () => {
  const { t } = useTranslation();
  const { isSubmitting, values, status, dirty } = useFormikContext();
  const fields = useRegisterFields(values[INPUT_IDS.REFERRER].value);

  return (
    <>
      <div className="row">
        {fields?.map(
          ({ colClass = 'col-12', colCss = '', id, ...restProps }) => {
            return (
              <div className={colClass} key={id} css={colCss}>
                <InputsIndex id={id} {...restProps} />
              </div>
            );
          }
        )}
      </div>
      {status?.apiErrorMessage && (
        <Alert styles={{ css: tw`mb-4` }}>
          {/* i18next-extract-disable-next-line */}
          {t(`$*error.api.${status.apiErrorMessage}`)}
        </Alert>
      )}

      <div tw="mt-6">
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !dirty}
          fullWidth={true}
          data-cy="register-form__submit"
        >
          {t('$*registerPage.createAccount', 'Załóż konto')}
        </Button>
      </div>
    </>
  );
};

export default RegisterFormFields;
