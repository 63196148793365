const PiggyBankIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.33 512 341.33"
  >
    {title && <title>{title}</title>}
    <path d="M106.667 192c-11.76 0-21.333 9.573-21.333 21.333 0 11.76 9.573 21.333 21.333 21.333 11.76 0 21.333-9.573 21.333-21.333 0-11.76-9.573-21.333-21.333-21.333zM333.99 139.198c-44.646-14.646-111.333-14.646-155.979 0-5.604 1.833-8.646 7.865-6.813 13.458 1.844 5.615 7.875 8.688 13.458 6.813 40.417-13.271 102.271-13.271 142.688 0a10.664 10.664 0 0013.458-6.813c1.833-5.593-1.208-11.625-6.812-13.458z" />
    <path d="M501.333 149.333c-4.844 0-9.5-.49-13.896-1.375 2.031-9.188 3.229-19.385 3.229-30.625 0-17.646-14.354-32-32-32s-32 14.354-32 32c0 17.531 14.031 34.073 34.156 43.958-3.081 7.158-6.747 13.16-10.535 18.315C416.771 124.107 342.383 85.333 256 85.333c-49.823 0-97.74 13.021-135.938 36.813-13.01-10.031-28.844-15.479-45.396-15.479a10.66 10.66 0 00-10.667 10.667c0 14.719 4.51 29.125 12.833 41.427-14.302 16.698-24.406 35.292-29.656 54.573h-4.51C19.135 213.333 0 232.469 0 256c0 23.74 12.031 45.406 32.177 57.979l74.49 46.406V416a10.66 10.66 0 0010.667 10.667H160c4.042 0 7.729-2.281 9.542-5.896l12.625-25.25c47.208 13.021 100.458 13.021 147.667 0l12.625 25.25a10.671 10.671 0 009.542 5.896h42.667A10.66 10.66 0 00405.335 416v-56.479c40.75-29.979 64-71.323 64-114.188 0-15.542-3.115-30.527-8.65-44.745 7.227-8.059 14.656-18.559 20.327-32.203a92.293 92.293 0 0020.323 2.281c5.896 0 10.667-4.771 10.667-10.667s-4.773-10.666-10.669-10.666zM388.594 345.26a10.685 10.685 0 00-4.594 8.771v51.302h-25.406l-13.677-27.344c-2.354-4.698-7.75-7.021-12.74-5.406-48.229 15.146-104.125 15.146-152.354 0-5.01-1.583-10.396.729-12.74 5.406l-13.677 27.344H128v-50.865c0-3.688-1.896-7.104-5.021-9.052l-79.51-49.542C29.604 287.229 21.333 272.323 21.333 256c0-11.76 9.573-21.333 21.333-21.333h12.969c5.104 0 9.49-3.615 10.469-8.625 4.094-21.01 15.271-41.531 32.323-59.344 3.823-3.99 3.958-10.25.313-14.406-5.969-6.802-10.021-14.583-12-22.906 9.313 2.177 17.927 6.865 24.99 13.719a10.612 10.612 0 0013.417 1.167c35.75-24.25 82.219-37.604 130.854-37.604 105.865 0 192 62.208 192 138.667-.001 37.894-21.095 73.384-59.407 99.925zm78.583-204.77C455.323 133.75 448 124.448 448 117.333c0-5.885 4.781-10.667 10.667-10.667s10.667 4.781 10.667 10.667c-.001 8.427-.792 16.136-2.157 23.157z" />
  </svg>
);

export default PiggyBankIcon;
