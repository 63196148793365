import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import {
  changeDefaultDeliveryType,
  selectOrderForm,
  setDefaultAddress,
  setDefaultPickUpPoint,
} from '@store/orderForm/orderForm.slice';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import Box from '@components/elements/Box';
import DisplayUpXl from '@components/elements/DisplayOn/DisplayUpXl';
import HoverBasket from '@components/elements/HoverBasket';
import Tab from '@components/elements/Tabs/Tab';
import TabPanel from '@components/elements/Tabs/TabPanel';
import Tabs from '@components/elements/Tabs/Tabs';
import Basket from '@components/modules/Basket/Basket';
import {
  selectOrderTabsPick,
  selectTabs,
  setIsCurrentValid,
} from '@features/orderTabs/orderTabsSlice';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
  useAppMode,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useMediaQuery from '@hooks/useMediaQuery';
import useOrderForm from '@hooks/useOrderForm';
import usePickupPoints from '@hooks/usePickupPoints';
import useUserAddresses from '@hooks/useUserAddresses';
import { up } from '@utils/screens';
import showToast from '@utils/showToast';
import useValidateOrder from '@views/NewOrder/NewOrderCreatePage/useValidateOrder';

const AddressesList = dynamic(() =>
  import('@components/modules/AddressesList/AddressesList')
);
const ApiLoadingBoundary = dynamic(() =>
  import('@components/elements/Loading/ApiLoadingBoundary')
);
const Button = dynamic(() => import('@components/elements/Button'));

const OrderFormDelivery = ({ currentStepNumber }) => {
  const currentStepSlug = useSelector(state =>
    selectOrderTabsPick(state, ['currentStepSlug'])
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: pickupPoints = [] } = usePickupPoints();
  const [isOrderFormRefetched, setIsOrderFormRefetched] = useState(false);
  const { refetch: refetchOrderForm } = useOrderForm({
    enabled: false,
  });

  useEffect(() => {
    handleOrderFormRefetch();
  }, []);

  const handleOrderFormRefetch = async () => {
    try {
      await refetchOrderForm();
      setIsOrderFormRefetched(true);
    } catch (e) {
      setIsOrderFormRefetched(true);
    }
  };

  const {
    data: addresses = [],
    isLoading,
    isFetched,
    isError,
    refetch,
  } = useUserAddresses();
  const {
    changeDeliveryType,
    currentDiet: { address, pickUpPoint, deliveryType },
    handleUpdateBasketDiet,
    setAddress,
    setPickupPoint,
    updateOrderBasketModify,
  } = useBasketMethods();
  const { isOnlyShop } = useAppMode();

  const {
    deliveryOptions: {
      pickUpPoint: isPickUpPointEnabled,
      address: isAddressEnabled,
    } = {},
    allowStickyNextButton,
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const [activeTabIndex, setActiveTabIndex] = useState(
    isAddressEnabled ? 0 : 1
  );

  const { defaultDeliveryType, defaultAddress, defaultPickUpPoint } =
    useSelector(selectOrderForm);
  const data = isOnlyShop
    ? {
        address: defaultAddress,
        deliveryType: defaultDeliveryType,
        pickUpPoint: defaultPickUpPoint,
      }
    : { address, deliveryType, pickUpPoint };
  const { isValid } = useValidateOrder(data);
  const { isSetInititalState } = useSelector(selectTabs);

  const isUp620 = useMediaQuery(up('620px'), true);

  useEffect(() => {
    if (isAddressEnabled && isPickUpPointEnabled) {
      const currentDeliveryType = isOnlyShop
        ? defaultDeliveryType
        : deliveryType;
      const index =
        (!currentDeliveryType || currentDeliveryType === 'ADDRESS') &&
        isAddressEnabled
          ? 0
          : 1;
      handleChangeTab(index);
    } else {
      handleChangeTab(isAddressEnabled ? 0 : 1);
    }
  }, []);

  useEffect(() => {
    const currentDeliveryType = isOnlyShop ? defaultDeliveryType : deliveryType;

    if (
      isFetched &&
      isOrderFormRefetched &&
      !isEmpty(addresses) &&
      isEmpty(address) &&
      currentDeliveryType === 'ADDRESS'
    ) {
      const defaultAddress = addresses.find(({ isDefault }) => isDefault);

      if (!isEmpty(defaultAddress?.zone)) {
        handleSetAddress(defaultAddress);
        dispatch(setIsCurrentValid());
      }
    }
  }, [isFetched, isOrderFormRefetched, addresses]);

  useEffect(() => {
    if (isFetched && isSetInititalState && isOrderFormRefetched) {
      dispatch(setIsCurrentValid(isValid));
    }
  }, [isFetched, isValid, isSetInititalState, isOrderFormRefetched]);

  const handleSetAddress = address => {
    if (isOnlyShop) {
      dispatch(setDefaultAddress(address));

      updateOrderBasketModify({
        payload: {
          delivery: {
            address: address['@id'],
          },
        },
        stepNumber: currentStepNumber,
      });
    } else {
      dispatch(setAddress(address));

      handleUpdateBasketDiet({
        delivery: { address: address['@id'] },
      }).catch(error => {
        console.log(error);
      });
    }
  };

  const handleSetPickupPoint = pickUpPoint => {
    if (isOnlyShop) {
      dispatch(setDefaultPickUpPoint(pickUpPoint));

      updateOrderBasketModify({
        payload: {
          delivery: {
            pickUpPoint: pickUpPoint['@id'],
          },
        },
        stepNumber: currentStepNumber,
      });
    } else {
      dispatch(setPickupPoint(pickUpPoint));

      handleUpdateBasketDiet({
        delivery: {
          pickUpPoint: pickUpPoint['@id'],
        },
      });
    }
  };

  const handleSuccessAddAddress = address => {
    handleSetAddress(address);
  };

  const handleSuccessUpdateAddress = address => {
    handleSetAddress(address);
  };

  const handleChangeTab = index => {
    const deliveryType = {
      0: 'ADDRESS',
      1: 'PICKUP_POINT',
    };

    setActiveTabIndex(index);

    if (isOnlyShop) {
      return dispatch(changeDefaultDeliveryType(deliveryType[index]));
    }

    dispatch(changeDeliveryType(deliveryType[index]));
  };

  return (
    <div tw="flex justify-end">
      <div className="col--main-center">
        <Box styles={{ css: tw`relative` }}>
          <Tabs activeTabIndex={activeTabIndex} onChange={handleChangeTab}>
            {isAddressEnabled && (
              <Tab
                label={t(
                  '$*newOrderCreatePage.orderForm.delivery.addressDelivery',
                  'Dostawa na adres'
                )}
                index={0}
                data-cy="tab-delivery--address"
              />
            )}
            {isPickUpPointEnabled && (
              <Tab
                label={t(
                  '$*newOrderCreatePage.orderForm.delivery.pickupAtPoint',
                  'Odbiór w punkcie'
                )}
                index={1}
                data-cy="tab-delivery--pickup-point"
              />
            )}
          </Tabs>
          {isAddressEnabled && (
            <TabPanel activeTabIndex={activeTabIndex} index={0}>
              <div tw="pt-3">
                <ApiLoadingBoundary
                  isError={isError}
                  isLoading={isLoading}
                  refetch={refetch}
                >
                  <AddressesList
                    allowStickyNextButton={allowStickyNextButton}
                    currentStepSlug={currentStepSlug}
                    isFetched={isFetched}
                    addresses={addresses}
                    canAddNewAddress={true}
                    onClickAddress={address => {
                      if (!address?.zone) {
                        return showToast(
                          t(
                            '$*newOrderCreatePage.orderForm.delivery.addressIsNotAvaliable',
                            'Wybrany adres jest nieobsługiwany, proszę wybrać inny.'
                          )
                        );
                      }

                      handleSetAddress(address);
                    }}
                    selectedAddresses={
                      isOnlyShop
                        ? defaultAddress?.id
                          ? [defaultAddress?.id]
                          : []
                        : address?.id
                        ? [address?.id]
                        : []
                    }
                    onSuccessUpdateAddress={handleSuccessUpdateAddress}
                    onSuccessAddAddress={handleSuccessAddAddress}
                    styles={{
                      address: {
                        wrapper: css`
                          ${isUp620 && 'max-width: 50%;'}
                        `,
                      },
                    }}
                  />
                </ApiLoadingBoundary>
              </div>
            </TabPanel>
          )}
          {isPickUpPointEnabled && (
            <TabPanel activeTabIndex={activeTabIndex} index={1}>
              <div tw="pt-3">
                <p>
                  {t(
                    '$*newOrderCreatePage.orderForm.delivery.pickupDiscount',
                    'Skorzystaj z rabatu za odbiór w punkcie'
                  )}
                </p>
                <div tw="-m-2">
                  {pickupPoints.map(item => {
                    const currentPickupPoint = isOnlyShop
                      ? defaultPickUpPoint
                      : pickUpPoint;
                    const isSelected =
                      currentPickupPoint?.['@id'] === item['@id'];

                    return (
                      <div key={item.id} tw="p-2">
                        <Button
                          variant="outlined"
                          fullWidth={true}
                          onClick={() => {
                            handleSetPickupPoint(item);
                          }}
                          css={[isSelected && tw`text-white bg-primary`]}
                          data-cy="pickup-point"
                        >
                          {item.value}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </TabPanel>
          )}
        </Box>
      </div>
      <DisplayUpXl>
        <div className="col--main-right">
          <div tw="sticky top-28 z-20">
            <Basket />
            <HoverBasket />
          </div>
        </div>
      </DisplayUpXl>
    </div>
  );
};

export default OrderFormDelivery;
