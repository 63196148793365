import { useTranslation } from 'next-i18next';

import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import tw from 'twin.macro';

import ShareSquareIcon from '@assets/icons/ShareSquareIcon';
import Tooltip from '@components/elements/Tooltip';

const ShareBasketButton = ({ onClick }) => {
  const { t } = useTranslation();
  const isPackageLineFilter = useSelector(
    state => state?.basketInitialData?.diet?.packageLineFilters
  );

  return (
    <Tooltip
      content={
        <div>
          <div tw="text-center pb-1">
            {t('$*common.shareBasket.title', 'Udostępnij koszyk')}
          </div>
          {t(
            '$*common.shareBasket.description',
            'Udostępnienie koszyka powoduje utworzenie nowego jako kopia istniejącego oraz skopiowanie adresu do schowka.'
          )}
        </div>
      }
    >
      <div
        css={isEmpty(isPackageLineFilter) ? tw`ml-3` : tw`hidden`}
        onClick={onClick}
      >
        <ShareSquareIcon tw="w-4 cursor-pointer" />
      </div>
    </Tooltip>
  );
};

export default ShareBasketButton;
