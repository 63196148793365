import { css } from '@emotion/react';
import tw, { theme } from 'twin.macro';

const styles = {
  wrapper: tw`relative w-full`,
  image: tw`object-contain w-full max-w-full`,
  contentWrapper: tw`absolute top-0 w-full`,

  titleWrapper: css`
    font-size: 10vw;
    @media (min-width: ${theme`screens.lg`}) {
      font-size: 5vw;
    }
    ${tw`font-black leading-normal uppercase`}
  `,

  descriptionWrapper: css`
    ${tw`leading-normal`}
    margin-top: 3vh;
    font-size: 7vw;

    @media (min-width: ${theme`screens.lg`}) {
      font-size: 2vw;
      margin-top: 1vh;
    }

    ul {
      list-style: initial;
    }

    li {
      list-style-position: inside;
    }

    ol {
      list-style: decimal;
    }
  `,
};

export default styles;
