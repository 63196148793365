import { createContext, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addDays, getDay, isAfter, isBefore } from 'date-fns';
import produce from 'immer';
import isNil from 'lodash/isNil';

import useDietTimeSlots from '@hooks/basket/useDietTimeSlots';
import {
  selectLastPossibleOrderDay,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useBlockDelivery from '@hooks/useBlockDelivery';
import useOrderForm from '@hooks/useOrderForm';
import { getIDfromIRI } from '@services/Api.service';
import { getFormattedDisabledDaysOfWeek } from '@services/Calendar.service';
import { format, formatArray } from '@services/Date.service';
import { isDate } from '@utils/helpers';

const NewOrderCreatePageContext = createContext([{}, () => {}]);

const NewOrderCreatePageProvider = ({ children }) => {
  const dispatch = useDispatch();

  const orderFormQuery = useOrderForm({ enabled: false });
  const [getDietTimeSlots] = useDietTimeSlots();
  const {
    getBlockDays,
    checkIfDayIsDeliveredInBlock,
    getCurrentlyAvailableBlockSizes,
  } = useBlockDelivery();
  const stickyDaysRef = useRef(null);
  const lastPossibleOrderDay = useAppConfigSelector(selectLastPossibleOrderDay);

  const {
    basketStore,
    handleUpdateBasketDiet,
    setRevalidateBasket,
    currentDiet,
    shouldScrollIntoVariants,
  } = useBasketMethods();

  const {
    disabledDays = [],
    disabledDaysOfWeek = [],
    firstPossibleOrderDay = new Date(),
  } = getDietTimeSlots(getIDfromIRI(currentDiet?.dietId));

  const turnedOffDaysOfWeek =
    [
      isNil(currentDiet?.saturdayInclude)
        ? disabledDaysOfWeek.includes(6)
          ? 6
          : null
        : currentDiet?.saturdayInclude
        ? null
        : 6,

      isNil(currentDiet?.sundayInclude)
        ? disabledDaysOfWeek.includes(7)
          ? 0
          : null
        : currentDiet?.sundayInclude
        ? null
        : 0,
    ].filter(Number.isInteger) ?? [];

  const isDateValid = (date, validitySettings = {}) => {
    const formattedDisabledDaysOfWeek = getFormattedDisabledDaysOfWeek(
      validitySettings?.disabledDaysOfWeek ?? disabledDaysOfWeek
    );

    return (
      !(validitySettings?.disabledDays ?? disabledDays).includes(
        format(date)
      ) &&
      !turnedOffDaysOfWeek.includes(getDay(date)) &&
      !formattedDisabledDaysOfWeek.includes(getDay(date))
    );
  };

  const isDateAfterLastPossibleDate = date => {
    return isAfter(date, lastPossibleOrderDay);
  };

  const selectDaysByRange = (startDate, range, validitySettings = {}) => {
    let selectedDays = [];
    let currentDate = startDate;
    let securityCounter = 0;

    // SecurityCounter is added to avoid infinite loop when block delivery config is improperly configured
    while (selectedDays.length < range && securityCounter < 300) {
      if (
        isDateValid(currentDate, validitySettings) &&
        !formatArray(selectedDays).includes(format(currentDate))
      ) {
        const isCurrentDayDeliveredInBlock = checkIfDayIsDeliveredInBlock(
          new Date(currentDate)
        );

        if (isCurrentDayDeliveredInBlock) {
          const daysLeftToSelect = range - selectedDays.length;
          const currentlyAvailableBlockSizes =
            getCurrentlyAvailableBlockSizes(daysLeftToSelect);
          const blockDays = getBlockDays(new Date(currentDate));

          if (currentlyAvailableBlockSizes.includes(blockDays?.length))
            selectedDays = [...selectedDays, ...blockDays];
        } else {
          selectedDays.push(currentDate);
        }
      }

      currentDate = addDays(new Date(currentDate), 1);
      securityCounter++;
    }

    if (isDate(lastPossibleOrderDay)) {
      selectedDays = selectedDays.filter(
        date => !isDateAfterLastPossibleDate(date)
      );
    }
    return selectedDays;
  };

  const hasBasketInvalidDates = basketStore.items.dietElements.some(
    dietElement => {
      const { disabledDays, disabledDaysOfWeek, firstPossibleOrderDay } =
        getDietTimeSlots(getIDfromIRI(dietElement?.dietId));
      const validitySettings = { disabledDays, disabledDaysOfWeek };

      const currentFirstDayIsIncorrect = isBefore(
        new Date(dietElement.firstDeliveryDay),
        new Date(firstPossibleOrderDay)
      );
      const hasInvalidDeliveryDates = dietElement.deliveryDates.some(
        date =>
          isBefore(new Date(date), new Date(firstPossibleOrderDay)) ||
          !isDateValid(new Date(date), validitySettings)
      );

      return currentFirstDayIsIncorrect || hasInvalidDeliveryDates;
    }
  );

  useEffect(() => {
    if (hasBasketInvalidDates) {
      const revalidatedBasketStore = produce(basketStore, draftState => {
        draftState.items.dietElements.map(dietElement => {
          const { firstPossibleOrderDay } = getDietTimeSlots(
            getIDfromIRI(dietElement?.dietId)
          );
          const validitySettings = { disabledDays, disabledDaysOfWeek };

          const currentFirstDayIsIncorrect = isBefore(
            new Date(dietElement.firstDeliveryDay),
            new Date(firstPossibleOrderDay)
          );
          const hasInvalidDeliveryDates = dietElement.deliveryDates.some(
            date =>
              isBefore(new Date(date), new Date(firstPossibleOrderDay)) ||
              !isDateValid(new Date(date), validitySettings)
          );

          if (currentFirstDayIsIncorrect || hasInvalidDeliveryDates) {
            const newDeliveryDates = formatArray(
              selectDaysByRange(
                new Date(firstPossibleOrderDay),
                dietElement.dietLength
              )
            );
            const deliveryDates =
              dietElement.paymentMode === 'SINGLE_PAYMENT'
                ? newDeliveryDates
                : [firstPossibleOrderDay];

            dietElement.firstDeliveryDay = firstPossibleOrderDay;
            dietElement.deliveryDates = deliveryDates;

            handleUpdateBasketDiet({
              deliveryDates,
            });
          }
        });
      });
      dispatch(setRevalidateBasket(revalidatedBasketStore));
    }
  }, [firstPossibleOrderDay, hasBasketInvalidDates]);

  const value = {
    orderFormQuery,
    selectDaysByRange,
    turnedOffDaysOfWeek,
    shouldScrollIntoVariants,
    stickyDaysRef,
  };

  return (
    <NewOrderCreatePageContext.Provider value={value}>
      {children}
    </NewOrderCreatePageContext.Provider>
  );
};

const useNewOrderCreatePageContext = () =>
  useContext(NewOrderCreatePageContext);

export { NewOrderCreatePageProvider, useNewOrderCreatePageContext };
