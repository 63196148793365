import Tippy, { type TippyProps } from '@tippyjs/react';

import InfoIcon from '@components/elements/InfoIcon/InfoIcon';

const InfoIconTooltip = (props: TippyProps) => {
  if (!props.content) {
    return null;
  }

  return (
    <Tippy {...props} offset={[0, 2]}>
      <InfoIcon />
    </Tippy>
  );
};

export default InfoIconTooltip;
