const MapMarkerIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2109 2835"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M1058.39.022L1053.96 0C472.81 0 .01 472.845.01 1054.058c0 318.65 162.87 567.18 344.33 814.68l708.21 965.91s669.39-908.8 711.26-965.89c181.47-247.5 344.34-496.03 344.34-814.68C2108.15 474.294 1637.66 2.314 1058.39.022zm-.63 129.165h.12c508.28 2.012 921.1 416.159 921.1 924.891 0 289.81-154.29 513.21-319.34 738.3-31.46 42.9-417.02 566.4-606.85 824.14l-604.28-824.16c-165.04-225.09-319.34-448.49-319.34-738.3 0-509.869 414.67-924.701 924.44-924.891l4.15.02zm-3.67 1431.891c279.55 0 507.02-227.44 507.02-507.02 0-279.56-227.47-506.997-507.02-506.997-279.59 0-507.02 227.437-507.02 506.997 0 279.58 227.43 507.02 507.02 507.02zm0-129.16c-208.36 0-377.86-169.5-377.86-377.86 0-208.34 169.5-377.83 377.86-377.83 208.33 0 377.85 169.49 377.85 377.83 0 208.36-169.51 377.86-377.85 377.86z" />
  </svg>
);

export default MapMarkerIcon;
