import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';

import { Fragment, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { loginWithUserData, register } from '@store/auth/auth.actions';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import ExternalAuth from '@components/elements/ExternalAuth/ExternalAuth';
import LOGIN_INPUT_IDS from '@constants/inputs/loginInputs';
import REGISTER_INPUT_IDS from '@constants/inputs/registerInputs';
import useGetApiErrorMessages from '@hooks/useGetApiErrorMessages';
import { pushLoginGTMEvent, pushRegisterGTMEvent } from '@utils/gtm';
import { triggerDeclarationEvent } from '@utils/gtmTs';

import { initialValues, useValidationSchema } from './formikData';
import RegisterFormFields from './RegisterFormFields';

const RegisterForm = () => {
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const getApiErrorMessages = useGetApiErrorMessages();
  const validationSchema = useValidationSchema();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFormikSubmit = (
    values,
    { resetForm, setStatus, setSubmitting }
  ) => {
    const payload = { ...values, knowAboutUs: values.knowAboutUs.value };
    dispatch(register(payload))
      .then(unwrapResult)
      .then(userData => {
        pushRegisterGTMEvent({
          provider: 'email',
          ctaType: 'button',
          pagePath: router.asPath,
          userId: userData.id,
        });

        return dispatch(
          loginWithUserData({
            [LOGIN_INPUT_IDS.USERNAME]: values[REGISTER_INPUT_IDS.EMAIL],
            [LOGIN_INPUT_IDS.PASSWORD]: values[REGISTER_INPUT_IDS.PASSWORD],
            [LOGIN_INPUT_IDS.KEEP_ME_LOGGED_IN]:
              values[REGISTER_INPUT_IDS.KEEP_ME_LOGGED_IN],
          })
        );
      })
      .then(unwrapResult)
      .then(({ userData }) => {
        pushLoginGTMEvent({
          provider: 'email',
          ctaType: 'auto',
          pagePath: router.asPath,
          userId: userData.id,
        });

        setStatus();
        resetForm();
        triggerDeclarationEvent(userData);
      })
      .catch(error => {
        setStatus(getApiErrorMessages(error));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const hasParagraph = !isEmpty(t('$*registerPage.paragraph'));
  const hasListText = !isEmpty(t('$*registerPage.advantagesList'));

  return (
    <Fragment>
      {hasParagraph && (
        <p tw="text-gray-3">
          {t(
            '$*registerPage.paragraph',
            'Utwórz konto, które pozwoli Ci samodzielnie i o dogodnej dla Ciebie porze:'
          )}
        </p>
      )}
      {hasListText && (
        <ul tw="mb-4 md:mb-5 lg:mb-8 list-disc ml-5">
          <Trans
            i18nKey="$*registerPage.advantagesList"
            components={[<li key="0" />]}
          />
        </ul>
      )}
      <ExternalAuth
        orText={t(
          '$*components.registerForm.normalRegister',
          'lub kontynuuj przez'
        )}
        isRegister={true}
        onAfterLogin={triggerDeclarationEvent}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormikSubmit}
      >
        <Form data-cy="register-form">
          <RegisterFormFields />
        </Form>
      </Formik>
    </Fragment>
  );
};

export default RegisterForm;
