import { forwardRef } from 'react';

import CircleInfoIcon from '@assets/icons/CircleInfoIcon';

const InfoIcon = forwardRef<HTMLSpanElement>((_props, ref) => {
  return (
    <span tw="inline-block p-2 -m-2" ref={ref}>
      <CircleInfoIcon tw="w-4 h-4 fill-gray-3" />
    </span>
  );
});

InfoIcon.displayName = 'InfoIcon';

export default InfoIcon;
