import { Fragment } from 'react';
import clsx from 'clsx';
import tw from 'twin.macro';

import Image from '@components/elements/Image/Image';
import ImageWrapper from '@components/elements/Image/ImageWrapper';
import useMediaQuery from '@hooks/useMediaQuery';
import { screensInt, up } from '@utils/screens';

import styles from './Banner.styles';

const BannerContent = ({ banner, children, containerized }) => {
  return (
    <div
      className={clsx('banner__content', containerized && ' container')}
      css={styles.contentWrapper}
    >
      <div
        className="banner__title"
        dangerouslySetInnerHTML={{
          __html: banner?.title,
        }}
        css={styles.titleWrapper}
      />
      <div
        className="banner__description"
        dangerouslySetInnerHTML={{
          __html: banner?.description,
        }}
        css={styles.descriptionWrapper}
      />
      {children}
    </div>
  );
};

const Banner = ({
  banner = {},
  isDisabled = false,
  children,
  containerized,
}) => {
  const isUpXl = useMediaQuery(up('xl'), true);

  if (!banner?.enable || isDisabled) {
    return null;
  }

  return (
    <Fragment>
      <div className="banner" css={styles.wrapper}>
        {banner?.imageDesktop && isUpXl && (
          <div
            className="banner__inner-wrapper banner__inner-wrapper--desktop"
            css={
              containerized
                ? tw`justify-center hidden xl:flex`
                : tw`hidden xl:block`
            }
          >
            <ImageWrapper>
              <Image
                src={banner?.imageDesktop}
                css={styles.image}
                alt=""
                priority={true}
                layout="fill"
                sizes="1920px"
              />
            </ImageWrapper>
            <BannerContent banner={banner} containerized={containerized}>
              {children}
            </BannerContent>
          </div>
        )}
        {banner?.imageMobile && !isUpXl && (
          <div
            className="banner__inner-wrapper banner__inner-wrapper--mobile"
            tw="block xl:hidden"
          >
            <ImageWrapper>
              <Image
                src={banner?.imageMobile}
                css={styles.image}
                alt=""
                priority={true}
                layout="fill"
                sizes={`${screensInt.xl}px`}
              />
              <BannerContent banner={banner}>{children}</BannerContent>
            </ImageWrapper>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Banner;
