import { useTranslation } from 'next-i18next';

import { isAxiosError } from 'axios';

import { transformApiErrors } from '@services/Api.serviceTS';

const useGetApiErrorMessages = () => {
  const { t } = useTranslation();

  return (error: unknown) => {
    if (isAxiosError(error) && typeof error.response === 'object') {
      const { status, data } = error.response;

      switch (status) {
        case 400:
        case 422:
          return {
            apiErrorMessage: '',
            apiFieldErrors: transformApiErrors(data),
          };
        case 429:
          return {
            apiErrorMessage: t('$*error.api.tooManyRequests'),
            apiFieldErrors: {},
          };
        default:
          return {
            apiErrorMessage: t('$*errorPage.title'),
            apiFieldErrors: {},
          };
      }
    }

    return {
      apiErrorMessage: '',
      apiFieldErrors: {},
    };
  };
};

export default useGetApiErrorMessages;
