import ClientPanelConfigProvider from '@contexts/ClientPanelConfig.context';

import { NewOrderCreatePageProvider } from './NewOrderCreatePage.context';
import NewOrderCreatePageView from './NewOrderCreatePage.view';

const NewOrderCreatePage = () => {
  return (
    <ClientPanelConfigProvider>
      <NewOrderCreatePageProvider>
        <NewOrderCreatePageView />
      </NewOrderCreatePageProvider>
    </ClientPanelConfigProvider>
  );
};

export default NewOrderCreatePage;
