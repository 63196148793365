import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { selectInitialDiet } from '@store/basket/basketInitialData.slice';
import tw from 'twin.macro';

import Box from '@components/elements/Box';
import Button from '@components/elements/Button';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import PaymentOptions from '@components/modules/Basket/BasketNormal/PaymentOptions';
import {
  changeStep,
  changeStepAndResetValid,
} from '@features/orderTabs/orderTabsSlice';
import { useAppMode } from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useMediaQuery from '@hooks/useMediaQuery';
import useMobileDetect from '@hooks/useMobileDetect';
import { pickRenameKeysInitialDiet } from '@services/Basket.service';
import { checkIfStandardPaymentTypesAvailable } from '@utils/paymentTypesHelpers';
import { up } from '@utils/screens';
import { down } from '@utils/screens';

import OrderItems from './OrderItems/OrderItems';
import OrderDetails from './OrderDetails';

const OrderFormSummary = ({
  currentStepNumber,
  handleAddBasketDiet,
  setStatusBasket,
  basketData,
}) => {
  const { t } = useTranslation();

  const isUpXl = useMediaQuery(up('xl'), true);
  const isDown1200 = useMediaQuery(down('1200px'), true);

  const initialDiet = useSelector(selectInitialDiet);
  const { isDietFull } = useAppMode();

  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  const { testMode } = basketData;
  const handleCloseBasket = () => dispatch(setStatusBasket(false));
  const dispatch = useDispatch();
  const {
    basketQuery: { data: basket = {} },
  } = useBasketMethods();

  const handleMobileAction = () => {
    window.scrollTo({ top: 0 });

    if (isMobile) {
      handleCloseBasket();
    }
  };
  const handleAddMore = async () => {
    if (isDietFull) {
      await handleAddBasketDiet(pickRenameKeysInitialDiet(initialDiet));

      dispatch(changeStepAndResetValid(0));
    } else {
      dispatch(changeStep(0));
    }

    handleMobileAction();
  };

  const areStandardPaymentTypesAvailable = basket?.paymentTypes
    ? checkIfStandardPaymentTypesAvailable(basket.paymentTypes)
    : false;

  return (
    <div tw="flex flex-col xl:flex-row">
      <div tw="w-full xl:w-3/4">
        <OrderDetails styles={{ css: tw`mb-4` }} />
        <OrderItems />
      </div>
      <div tw="w-full xl:w-1/2 xl:ml-8">
        <Box size="md" styles={{ css: tw`relative mt-4 lg:mt-0` }}>
          {areStandardPaymentTypesAvailable && (
            <h3 className="h3" tw="pb-1.5 mb-8 border-b border-gray-1">
              {t(
                '$*newOrderCreatePage.orderForm.summary.paymentTypes',
                'Metoda płatności'
              )}
            </h3>
          )}
          <PaymentOptions
            currentStepNumber={currentStepNumber}
            showBasketPay={isUpXl}
          />
        </Box>

        {!isDown1200 && isDietFull && !testMode && (
          <div tw="mt-10">
            <Button
              className="basket-action__button--add-more"
              fullWidth={true}
              onClick={handleAddMore}
              variant="outlined"
              data-cy="basket-action__button--add-more"
              styles={{ button: tw`bg-white` }}
            >
              {t('$*components.basket.addMore', '+ Dodaj kolejną dietę')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withMemoBasketMethods(OrderFormSummary, [
  { as: 'basketData', path: 'basketQuery.data', defaultValue: {} },
  'handleAddBasketDiet',
  'updateOrderBasketModify',
  'isEmptyBasket',
  'setStatusBasket',
]);
