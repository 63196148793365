import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import BinIcon from '@assets/icons/BinIcon';
import Clock2Icon from '@assets/icons/Clock2Icon';
import CopyIconDuplicateDocument from '@assets/icons/CopyIconSeccond';
import EditIcon from '@assets/icons/EditIcon';
import MapMarkerIcon from '@assets/icons/MapMarkerIcon';
import PriceTagIcon from '@assets/icons/PriceTagIcon';
import AddressLineInfo from '@components/elements/AddressLineInfo';
import Box from '@components/elements/Box';
import InfoIconTooltip from '@components/elements/InfoIconTooltip';
import PriceWrapper from '@components/elements/PriceWrapper';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import BasketDiet from '@components/modules/Basket/BasketNormal/BasketDiet';
import BasketDietDay from '@components/modules/Basket/BasketNormal/BasketDietDay';
import { changeStep } from '@features/orderTabs/orderTabsSlice';
import useAppMode from '@hooks/useAppMode';
import useAppModuleConfig from '@hooks/useAppModuleConfig';
import useBasket from '@hooks/useBasket';
import useDateServiceWithLocale from '@hooks/useDateServiceWithLocale';
import useDiscountTranslations from '@hooks/useDiscountTranslations';
import useMediaQuery from '@hooks/useMediaQuery';
import { transformDeliveryHourToString } from '@services/Api.service';
import { down } from '@utils/screens';
import { cssMerge } from '@utils/styleHelpers';

const BasketDietName = dynamic(() =>
  import(
    '@components/modules/Basket/BasketNormal/BasketDietName/BasketDietName'
  )
);

const DEFAULT_PRICE_OBJ = {
  afterDiscount: 0,
  beforeDiscount: 0,
  discount: 0,
};

const getPriceObj = (priceObj, defaultValues) =>
  Object.values(priceObj || {}).reduce(
    (acc, item) => ({
      ...acc,
      afterDiscount: acc.afterDiscount + item?.price?.afterDiscount ?? 0,
      beforeDiscount: acc.beforeDiscount + item?.price?.beforeDiscount ?? 0,
    }),
    defaultValues || DEFAULT_PRICE_OBJ
  );

/* TODO: refactor - separate others and diet, don't mix logic */

const OrderItem = ({
  diet,
  others,
  basketItems,
  activeOrderDietIri,
  duplicateBasketDiet,
  setActiveOrderDietIri,
  handleRemoveBasketDiet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { formatWithLocale } = useDateServiceWithLocale();
  const { data: basket } = useBasket({ basketKey: 'basketNewOrder' });
  const { isDietShop, isDietFull, isOnlyShop } = useAppMode();
  const { allowClientOwnDietName } = useAppModuleConfig('ConfigClientPanel');
  const discountTranslations = useDiscountTranslations();

  const address = !isEmpty(diet)
    ? diet?.address ?? {}
    : !isEmpty(others?.noDiet)
    ? Object.values(others?.noDiet)[0]?.delivery?.address ?? {}
    : {};

  const pickUpPoint = !isEmpty(diet)
    ? diet?.pickUpPoint?.value ?? null
    : !isEmpty(others?.noDiet)
    ? Object.values(others?.noDiet)[0]?.delivery?.pickUpPoint?.value ?? null
    : null;

  const getPrice = () => {
    if (diet) {
      return getPriceObj(diet.connectedElements?.noDiet, diet.price);
    }

    if (others?.noDiet) {
      if (isOnlyShop) {
        const { beforeDiscount, afterDiscount, discount } = basket.price;

        return {
          afterDiscount,
          beforeDiscount,
          discount,
        };
      }

      return getPriceObj(others.noDiet);
    }

    return DEFAULT_PRICE_OBJ;
  };

  const { afterDiscount, beforeDiscount, discountParts } = getPrice();

  const deliveryHourString = transformDeliveryHourToString({
    hour: {
      hourFrom: address?.deliveryHourFrom,
      hourTo: address?.deliveryHourTo,
    },
    translations: {
      bothHours: {
        from: t('$*common.deliveryHours.between', 'Pomiędzy'),
        connector: '',
        to: t('$*common.deliveryHours.betweenA', 'a'),
      },
      singularHour: { from: t('$*common.from'), to: t('$*common.to') },
    },
    t,
  });

  const canDeleteDiet =
    !isEmpty(diet) &&
    !(activeOrderDietIri === diet?.['@id']) &&
    (basketItems?.dietElements ?? []).length > 1;

  const firstDeliveryDate = !isEmpty(diet?.days)
    ? formatWithLocale(new Date(diet?.days[0]), 'EEEE, dd.MM.yyyy')
    : '';

  const lastDeliveryDate =
    diet?.days?.length > 1
      ? formatWithLocale(
          new Date(diet?.days[diet?.days.length - 1]),
          'EEEE, dd.MM.yyyy'
        )
      : '';

  const handleAddMore = async () => {
    duplicateBasketDiet(diet?.['@id']);
  };

  const isDownMd = useMediaQuery(down('md'), true);

  return (
    <Box
      data-cy="summary-step__order-item"
      size="md"
      styles={{
        css: cssMerge({
          defaultCss: [
            tw`mb-4 sm:mb-8`,
            activeOrderDietIri === diet?.['@id'] && tw`border-2 border-primary`,
          ],
        }),
      }}
    >
      {(allowClientOwnDietName ||
        activeOrderDietIri !== null ||
        activeOrderDietIri !== diet?.['@id'] ||
        canDeleteDiet) && (
        <div tw="flex border-b mb-4">
          <div tw="w-full sm:w-1/2">
            {allowClientOwnDietName && (isDietShop || isDietFull) && (
              <BasketDietName
                existingItem={diet?.['@id']}
                name={diet?.name}
                styles={{
                  css: tw`pt-0 pb-2 pr-0 text-xl font-semibold border-b-0 text-primary`,
                }}
              />
            )}
          </div>
          <div tw="sm:w-1/2 py-2 flex justify-end items-center ml-1">
            {activeOrderDietIri !== null && (
              <Tooltip
                isEnabled={!isDownMd}
                content={
                  <div>
                    {t(
                      '$*components.basketDiet.duplicateDiet',
                      'Duplikuj dietę'
                    )}
                  </div>
                }
              >
                <span>
                  <CopyIconDuplicateDocument
                    tw="cursor-pointer w-4 mx-1"
                    onClick={handleAddMore}
                  />
                </span>
              </Tooltip>
            )}
            {activeOrderDietIri !== null && (
              <Tooltip
                isEnabled={!isDownMd}
                content={
                  <div>
                    {t('$*components.basketDiet.editDiet', 'Edytuj dietę')}
                  </div>
                }
              >
                <span>
                  <EditIcon
                    tw="cursor-pointer w-4 mx-1"
                    onClick={e => {
                      e.stopPropagation();

                      if (activeOrderDietIri !== diet['@id']) {
                        dispatch(setActiveOrderDietIri(diet['@id']));
                      }

                      dispatch(changeStep(0));
                    }}
                  />
                </span>
              </Tooltip>
            )}

            {canDeleteDiet && (
              <Tooltip
                content={
                  <div>
                    {t('$*components.basketDiet.removeDiet', 'Usuń dietę')}
                  </div>
                }
              >
                <span>
                  <BinIcon
                    tw="cursor-pointer w-4 mx-1 text-red-1"
                    onClick={e => {
                      e.stopPropagation();
                      handleRemoveBasketDiet(diet?.['@id']);
                    }}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div tw="flex flex-col sm:flex-row">
        <div tw="w-full sm:w-1/2">
          {!isEmpty(diet) && (
            <BasketDiet
              diet={diet}
              showBinButton={false}
              showEditButton={false}
              showAddressLine={false}
              showBorderBottom={false}
              showDietNameInput={false}
              isExpeandedByDefault={false}
              showDietConnectedElements={false}
            />
          )}
          {!isEmpty(diet?.connectedElements) &&
            Object.entries(diet?.connectedElements).map(([dietIri, days]) => (
              <BasketDietDay
                key={dietIri}
                connectedToIri={diet['@id']}
                days={days}
                dietIri={dietIri}
                isExpeandedByDefault={false}
              />
            ))}
          {!isEmpty(others) && (
            <div>
              {Object.entries(others).map(([dietIri, days]) => (
                <BasketDietDay
                  key={dietIri}
                  days={days}
                  dietIri={dietIri}
                  isExpeandedByDefault={false}
                />
              ))}
            </div>
          )}
        </div>
        <div tw="w-full border-t sm:border-t-0 sm:w-1/2 sm:border-l border-gray-1 sm:ml-4 sm:pl-4">
          {/* ADDRESS */}
          <div tw="mt-2 mb-4 flex items-center">
            <MapMarkerIcon tw="w-5 mr-4" />
            <div tw="mr-2 text-sm">
              {!isEmpty(address) ? (
                <AddressLineInfo {...address} />
              ) : !isEmpty(pickUpPoint) ? (
                pickUpPoint
              ) : null}
            </div>
          </div>
          {/* DELIVERY DATES AND HOURS */}
          {(!!deliveryHourString || !isEmpty(diet)) && (
            <div tw="my-4 flex">
              <Clock2Icon tw="w-5 mr-4" />
              <div tw="text-sm">
                {!isOnlyShop && (
                  <div>{t('$*common.day', { count: diet?.days?.length })}</div>
                )}
                {!isEmpty(diet?.days) && (
                  <div>{`${capitalize(firstDeliveryDate)} ${
                    lastDeliveryDate
                      ? `- ${capitalize(lastDeliveryDate ?? '')}`
                      : ''
                  }`}</div>
                )}
                {!!deliveryHourString && (
                  <div tw="mr-2">{deliveryHourString}</div>
                )}
              </div>
            </div>
          )}
          {/* PRICE TAG */}
          <div tw="my-4 flex items-center">
            <PriceTagIcon tw="w-5 mr-4" />
            <div tw="mr-2 text-sm">{t('$*common.value', 'Wartość')}</div>
            {!isEmpty(discountParts) && (
              <InfoIconTooltip
                content={
                  <div>
                    <div tw="text-center pb-1">
                      {t(
                        '$*components.basket.usedDiscount',
                        'Udzielone rabaty'
                      )}
                    </div>
                    {Object.entries(discountParts || {}).map(([key, value]) => (
                      <div
                        key={key}
                        tw="flex justify-between text-sm -mx-4 font-semibold"
                      >
                        <div tw="px-4">{discountTranslations[key]}</div>
                        <PriceWrapper
                          afterDiscount={-value}
                          mayNegative={true}
                          styles={{ wrapper: { css: tw`px-4` } }}
                        />
                      </div>
                    ))}
                  </div>
                }
              />
            )}
            <PriceWrapper
              beforeDiscount={beforeDiscount}
              afterDiscount={afterDiscount}
              styles={{
                wrapper: {
                  css: tw`ml-auto text-base font-semibold sm:text-lg`,
                },
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default withMemoBasketMethods(OrderItem, [
  { as: 'basketItems', path: 'basketStore.items' },
  { as: 'activeOrderDietIri', path: 'basketStore.activeOrderDietIri' },
  'duplicateBasketDiet',
  'handleRemoveBasketDiet',
  'setActiveOrderDietIri',
]);
