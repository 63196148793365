import type { ReactNode } from 'react';
import Tippy, { type TippyProps } from '@tippyjs/react';
import isEmpty from 'lodash/isEmpty';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

type Props = {
  children?: ReactNode;
  isEnabled?: boolean;
} & TippyProps;

const Tooltip = ({ children, isEnabled = true, ...tooltipProps }: Props) => {
  if (isEmpty(children)) {
    return <Tippy {...tooltipProps} />;
  }

  if (!isEnabled) {
    return children;
  }

  return <Tippy {...tooltipProps}>{children}</Tippy>;
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
