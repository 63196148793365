import type { ReactNode } from 'react';

import DisplayOn from './DisplayOn';

type Props = {
  children: ReactNode;
};

const DisplayUpXl = ({ children }: Props) => {
  return (
    <DisplayOn size="xl" mode="up" defaultState={true}>
      {children}
    </DisplayOn>
  );
};

DisplayUpXl.displayName = 'DisplayUpXl';

export default DisplayUpXl;
