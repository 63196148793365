import { useTranslation } from 'next-i18next';

const useGetReferrerOtherOption = () => {
  const { t } = useTranslation();

  return {
    value: t('$*input.knowAboutUs.option.other.label'),
    position: 9999,
  };
};

export default useGetReferrerOtherOption;
