import type { FC } from 'react';
import type { ErrorObject } from 'serialize-error';

import CustomErrorPage from '@views/ErrorPage/CustomErrorPage';

type Props = {
  errorCode: number;
  errorPayload: ErrorObject;
};

const withCustomErrorBoundary = (Component: FC) => {
  const CustomErrorBoundary = ({
    errorCode,
    errorPayload,
    ...restProps
  }: Props) => {
    if (errorCode) {
      return (
        <CustomErrorPage statusCode={errorCode} errorPayload={errorPayload} />
      );
    }

    return <Component {...restProps} />;
  };

  CustomErrorBoundary.displayName = 'CustomErrorBoundary';

  return CustomErrorBoundary;
};

withCustomErrorBoundary.displayName = 'withCustomErrorBoundary';

export default withCustomErrorBoundary;
