const TabPanel = ({ activeTabIndex, index, children, ...restProps }) => {
  return (
    <div
      key={index}
      role="tabpanel"
      hidden={activeTabIndex !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...restProps}
    >
      {activeTabIndex === index && children}
    </div>
  );
};

TabPanel.displayName = 'TabPanel';

export default TabPanel;
