import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getPickupPoints = async () => {
  const response = await axios.get(ENDPOINT.DELIVERY_PICKUP_POINTS, null, true);

  return response?.data?.['hydra:member'] ?? [];
};

const usePickupPoints = (options = {}) => {
  return useQuery({
    queryKey: ['pickupPoints'],
    queryFn: getPickupPoints,
    ...options,
  });
};

export default usePickupPoints;
