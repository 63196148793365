const Clock2Icon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
  >
    {title && <title>{title}</title>}
    <path
      d="M7.5 13.979A6.486 6.486 0 0 1 1.021 7.5 6.479 6.479 0 1 1 7.5 13.979ZM14.95 7.5A7.45 7.45 0 0 0 7.5.05 7.458 7.458 0 0 0 .05 7.5a7.45 7.45 0 0 0 14.9 0Z"
      strokeWidth={0.1}
    />
    <path
      d="M8.035 7.014h-.05v-3.8a.486.486 0 1 0-.972 0V7.5c0 .268.218.486.486.486h4.286a.486.486 0 1 0 0-.972h-3.75Z"
      strokeWidth={0.1}
    />
  </svg>
);

export default Clock2Icon;
