import { memo } from 'react';
import fastDeepEqual from 'fast-deep-equal/es6';
import _get from 'lodash/get';

const withMemoContextCommon =
  useContext =>
  (Component, propsArray = []) => {
    const MemoComponent = memo(Component, fastDeepEqual);

    const MemoComponentWithProps = props => {
      const contextValues = useContext();
      const pickedProps = propsArray.reduce((acc, item) => {
        if (typeof item === 'object') {
          const { as, path, defaultValue } = item;

          return {
            ...acc,
            [as]: _get(contextValues, path, defaultValue),
          };
        }

        return {
          ...acc,
          [item]: _get(contextValues, item),
        };
      }, {});

      return <MemoComponent {...pickedProps} {...props} />;
    };

    return MemoComponentWithProps;
  };

export default withMemoContextCommon;
