import {
  Children,
  cloneElement,
  createRef,
  isValidElement,
  useState,
} from 'react';

import Underline from './Underline';

const Tabs = ({
  activeTabIndex = 0,
  onChange = () => {},
  children = null,
  ...restProps
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        activeTabIndex,
        onClick: onChange,
        ref: createRef(),
      });
    }

    return child;
  });

  const stepRefs = childrenWithProps.map(({ ref }) => ref);

  return (
    <div tw="relative pb-3 mb-4 overflow-hidden">
      <ul
        role="tablist"
        aria-orientation="horizontal"
        tw="flex -mx-2 -my-1"
        {...restProps}
      >
        {childrenWithProps}
      </ul>
      <Underline
        refs={stepRefs}
        activeTab={activeTabIndex}
        finishAnimating={() => setIsAnimating(false)}
        animating={isAnimating}
      />
    </div>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
