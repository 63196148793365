import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { selectDisableTracking } from '@store/nativeAppConfig/nativeAppConfig.slice';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import ConditionalWrapper from '@components/elements/ConditionalWrapper';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import BasketAction from '@components/modules/Basket/BasketNormal/BasketAction';
import MobileBasket from '@components/modules/Basket/BasketNormal/MobileBasket';
import BasketActionStatic from '@components/modules/Basket/BasketStatic/BasketAction';
import useBasketPrices from '@components/modules/Basket/BasketStatic/useBasketPrices';
import {
  selectBranding,
  selectModuleConfigClientPanel,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useMediaQuery from '@hooks/useMediaQuery';
import {
  pushCheckoutStepGTMEvent,
  pushViewCartGTMEvent,
  pushViewCartNewGTMEvent,
} from '@utils/gtm';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';
import { up } from '@utils/screens';

import {
  changeStep,
  prevStep,
  selectTabs,
  setIsAnimating,
  setTabsInitialState,
  updateSteps,
} from './orderTabsSlice';
import Tab from './Tab';
import Underline from './Underline';

const BasketPay = dynamic(() =>
  import('@components/modules/Basket/BasketNormal/BasketPay')
);

const OrderTabs = ({
  tabs = [],
  panels = [],
  dietElements,
  isBasketStatic,
  price,
  isMutatingBasket,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isPrevDisabled,
    currentStepIndex,
    steps,
    isAnimating,
    isSetInititalState,
  } = useSelector(selectTabs);
  const isUpMd = useMediaQuery(up('md'), true);
  const isUpXl = useMediaQuery(up('xl'), true);

  const basketPrices = useBasketPrices({ dietElements });
  const { name: brandName } = useAppConfigSelector(selectBranding);
  let { allowStickyNextButton } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const safeAllowStickyNextButton = allowStickyNextButton ?? true;

  const {
    currencySymbol,
    defaultRegion: { currencyCode },
  } = useAppConfigSelector(selectMultinational);
  const {
    basketQuery: { data: basket = {} },
  } = useBasketMethods();

  const disableTracking = useSelector(selectDisableTracking);

  useEffect(() => {
    if (!isSetInititalState) {
      const tabsWithValid = tabs.map(tab => ({
        ...tab,
        isValid: false,
      }));

      dispatch(setTabsInitialState(tabsWithValid));
    }
  }, [isSetInititalState]);

  useDeepCompareEffect(() => {
    dispatch(updateSteps(tabs));
  }, [tabs, currentStepIndex]);

  useEffect(() => {
    if (!disableTracking) {
      pushCheckoutStepGTMEvent({
        step: currentStepIndex + 1,
      });
    }
  }, [currentStepIndex]);

  useEffect(() => {
    pushViewCartGTMEvent({
      brandName,
      currencyCode,
      discountCode: basket?.discountCode?.code,
      items: basket?.rows,
      price: basket?.price?.afterDiscount,
    });
  }, []);

  const currentStep = steps[currentStepIndex] ?? {};
  const isAuthStep = currentStep?.slug === 'autoryzacja';
  const isSummaryStep = currentStep?.slug === 'podsumowanie';

  useEffect(() => {
    if (isSummaryStep) {
      pushViewCartNewGTMEvent({
        brandName,
        currencyCode,
        discountCode: basket?.discountCode?.code,
        items: basket?.rows,
        price: basket?.price?.afterDiscount,
      });
    }
  }, [isSummaryStep]);

  const isFirstStep = currentStepIndex === 0;
  const stepRefs = steps.reduce((acc, _, index) => {
    acc[index] = createRef();
    return acc;
  }, {});

  const scrollToTop = () => window.scrollTo({ top: 0 });
  const handleTab = tabIndex => {
    dispatch(changeStep(tabIndex));
    scrollToTop();
  };

  const handlePrevTab = () => {
    dispatch(prevStep());
    scrollToTop();
  };

  const showMobileBasket = isFirstStep && !isUpXl;
  const showBasketAction = !isFirstStep && !isUpXl && !isAuthStep;
  const showBasketPay = !isUpXl && isSummaryStep;
  const showPrevious = !isFirstStep && !isUpXl;

  const hasOnlyStickyBasketAction =
    showBasketAction && !showMobileBasket && !showBasketPay;
  const hasStickyElements =
    showMobileBasket || showBasketAction || showBasketPay;

  return (
    <>
      <div className="order-tabs">
        <div
          className="order-tabs__list"
          tw="bg-white rounded-md shadow-lg mb-8 relative flex-nowrap overflow-auto"
        >
          <ul
            aria-orientation="horizontal"
            className="order-tabs__nav"
            role="tablist"
            tw="flex"
          >
            {steps.map(({ label }, index) => {
              const prevTabIsValid = steps[index - 1]?.isValid ?? false;
              const currentTabIsValid = steps[index].isValid;
              const isActive = currentStepIndex === index;
              const currentTabIsDisabled =
                (prevTabIsValid ? !prevTabIsValid : !currentTabIsValid) ||
                (isMutatingBasket && !isActive);

              return (
                <Tab
                  key={index}
                  disabled={currentTabIsDisabled}
                  isActive={isActive}
                  ref={stepRefs[index]}
                  onClick={() => handleTab(index)}
                >
                  <span tw="text-xl md:text-3xl">{index + 1}</span>
                  {(isUpMd || isActive) && (
                    <span tw="uppercase text-sm pl-4">{label}</span>
                  )}
                </Tab>
              );
            })}
          </ul>
          <Underline
            refs={stepRefs}
            activeTab={currentStepIndex}
            finishAnimating={() => dispatch(setIsAnimating(false))}
            animating={isAnimating}
          />
        </div>
        {panels.map((PanelComponent, index) => {
          if (index !== currentStepIndex) {
            return null;
          }

          return (
            <div key={index} role="tabpanel" className="order-tabs__panel">
              <PanelComponent currentStepNumber={currentStepIndex + 1} />
            </div>
          );
        })}
      </div>

      {hasStickyElements && (
        <div
          css={[
            safeAllowStickyNextButton
              ? tw`sticky z-50 bottom-7`
              : tw`z-50 bottom-2`,
            showBasketPay ? tw`mt-4 sm:mt-8` : null,
          ]}
        >
          {showMobileBasket && <MobileBasket />}
          {showBasketAction && (
            <ConditionalWrapper
              condition={hasOnlyStickyBasketAction}
              wrapper={children => <div tw="pt-12">{children}</div>}
            >
              {isBasketStatic ? (
                <BasketActionStatic
                  isMobile={true}
                  buttonNextText={t('$*components.mobileBasket.button', {
                    defaultValue: 'Dalej ({{price}} {{currencySymbol}})',
                    replace: {
                      price: getIntegerIfIntegerOrFixed(
                        basketPrices?.price?.afterDiscount ?? 0
                      ),
                      currencySymbol,
                    },
                  })}
                />
              ) : (
                <BasketAction
                  isMobile={true}
                  buttonNextText={t('$*components.mobileBasket.button', {
                    defaultValue: 'Dalej ({{price}} {{currencySymbol}})',
                    replace: {
                      price: getIntegerIfIntegerOrFixed(
                        price?.afterDiscount ?? 0
                      ),
                      currencySymbol,
                    },
                  })}
                />
              )}
            </ConditionalWrapper>
          )}

          {showBasketPay && <BasketPay styles={tw`mt-2`} />}
        </div>
      )}

      {showPrevious && (
        <ConditionalWrapper
          condition={!hasStickyElements}
          wrapper={children => <div tw="pt-6">{children}</div>}
        >
          <Button
            onClick={handlePrevTab}
            disabled={isPrevDisabled}
            variant="outlined"
            styles={{
              button: tw`justify-center w-full my-2`,
            }}
            data-cy="order-tabs__button--prev"
          >
            {t('$*components.orderTabs.prevButton.label', 'Poprzedni krok')}
          </Button>
        </ConditionalWrapper>
      )}
      {isUpXl && !isFirstStep && !isSummaryStep && (
        <div tw="flex mt-9 justify-between flex-wrap -mx-4 -mb-2">
          <Button
            onClick={handlePrevTab}
            disabled={isPrevDisabled}
            variant="outlined"
            styles={{
              button: tw`justify-center flex-auto mx-4 my-2 sm:flex-initial`,
            }}
            data-cy="order-tabs__button--prev"
          >
            {t('$*components.orderTabs.prevButton.label', 'Poprzedni krok')}
          </Button>
        </div>
      )}
    </>
  );
};

export default withMemoBasketMethods(OrderTabs, [
  'isMutatingBasket',
  'isBasketStatic',
  { as: 'dietElements', path: 'basketStore.items.dietElements' },
  { as: 'price', path: 'basketQuery.data.price', defaultValue: {} },
  { as: 'rows', path: 'basketQuery.data.rows', defaultValue: [] },
]);
