import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import PiggyBankIcon from '@assets/icons/PiggyBankIcon';
import PriceTagIcon from '@assets/icons/PriceTagIcon';
import Box from '@components/elements/Box';
import InfoIconTooltip from '@components/elements/InfoIconTooltip';
import PriceWrapper from '@components/elements/PriceWrapper';
import Tag from '@components/elements/Tag';
import ClearBasketButton from '@components/modules/Basket/BasketHeader/ClearBasketButton';
import ShareBasketButton from '@components/modules/Basket/BasketHeader/ShareBasketButton';
import BASKET_ROWS_TYPES from '@constants/basketRowsTypes';
import useAppModuleConfig from '@hooks/useAppModuleConfig';
import useBasket from '@hooks/useBasket';
import useBasketMethods from '@hooks/useBasketMethods';
import useDiscountTranslations from '@hooks/useDiscountTranslations';
import { isFalsify } from '@utils/helpers';

const OrderDetails = ({ styles }) => {
  const { t } = useTranslation();

  const { icon, shortName, pluralNameGenitive, pluralNameDenominator } =
    useAppModuleConfig('MoneyBox');

  const { shareBasket, handleClickClearStaticBasket } = useBasketMethods();
  const { data: basket } = useBasket({ basketKey: 'basketNewOrder' });
  const discountTranslations = useDiscountTranslations();

  const discountParts = basket.price?.discountParts;
  const allBonusesCount = Object.values(basket.availableBonuses || {}).reduce(
    (acc, value) => acc + value,
    0
  );
  const diets = basket.rows
    ? basket.rows.filter(row => row['@type'] === BASKET_ROWS_TYPES.DIET_ITEM)
    : [];

  return (
    <Box data-cy="summary-step__summary-section" styles={styles} size="md">
      <div tw="flex justify-between items-center border-b border-gray-1 pb-1.5 mb-4">
        <h3 className="h3" tw="mb-0">
          {t(
            '$*newOrderCreatePage.orderForm.summary.orderDetails',
            'Podsumowanie'
          )}
        </h3>
        <div tw="flex items-center">
          <ClearBasketButton onClick={handleClickClearStaticBasket} />
          <ShareBasketButton onClick={shareBasket} />
        </div>
      </div>
      <div tw="flex flex-col sm:flex-row">
        <div tw="w-full sm:w-1/2">
          {diets.length > 0 && (
            <div>
              {diets.map(({ id, name, diet }) => (
                <div key={id} tw="mb-1">
                  <Tag
                    label={`${diet?.name || ''}${name ? `- ${name}` : ''}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div tw="w-full sm:w-1/2 border-t sm:border-t-0 sm:border-l border-gray-1 pt-4 mt-4 sm:mt-0 sm:pt-0 sm:ml-4 sm:pl-4">
          {/* POINTS YOU GET SECTION */}
          {!isFalsify(basket.availableBonuses) && (
            <div tw="flex py-2 text-sm items-center">
              <div tw="w-5 h-5 mr-4">
                {icon ? (
                  <img tw="w-5 h-5" src={icon} alt="" />
                ) : (
                  <PiggyBankIcon tw="w-full h-auto" />
                )}
              </div>
              <div tw="mr-2">
                {t('$*newOrderCreatePage.orderForm.summary.gainPoints', {
                  defaultValue: 'Zyskasz {{points}}',
                  replace: {
                    points:
                      pluralNameGenitive ||
                      t('$*common.pointsLiteral', 'punkty'),
                  },
                })}
              </div>
              <InfoIconTooltip
                content={
                  <div tw="p-2 text-xs sm:text-sm -my-3">
                    <div tw="flex justify-between py-3">
                      <div tw="flex-initial">
                        {t(
                          '$*newOrderCreatePage.orderForm.summary.pointsForOrder',
                          {
                            points:
                              pluralNameDenominator ||
                              t('$*common.pointsLiteral'),
                          }
                        )}
                      </div>
                      <div tw="shrink-0">
                        {`+${basket.availableBonuses?.AFTER_PAYMENT ?? 0} ${
                          pluralNameGenitive ||
                          t('$*common.pointsLiteral', 'punkty')
                        }`}
                      </div>
                    </div>
                  </div>
                }
                css={{ minWidth: '300px' }}
              />
              <div tw="ml-auto font-semibold">{`+${allBonusesCount} ${
                shortName || t('$*common.pointAbbreviation', 'pkt')
              }`}</div>
            </div>
          )}

          {/* SUMMED PRICE SECTION */}
          <div tw="flex py-2 text-sm items-center">
            <div tw="w-5 mr-4">
              <PriceTagIcon tw="w-full h-auto mt-0.5" />
            </div>
            <div tw="mr-2">
              {t('$*newOrderCreatePage.orderForm.summary.toPay', 'Do zapłaty')}
            </div>
            {!isEmpty(discountParts) && (
              <InfoIconTooltip
                content={
                  <div>
                    <div tw="text-center pb-1">
                      {t(
                        '$*components.basket.usedDiscount',
                        'Udzielone rabaty'
                      )}
                    </div>
                    {Object.entries(discountParts || {}).map(([key, value]) => (
                      <div
                        key={key}
                        tw="flex justify-between text-sm -mx-4 font-semibold"
                      >
                        <div tw="px-4">{discountTranslations[key]}</div>
                        <PriceWrapper
                          afterDiscount={-value}
                          mayNegative={true}
                          styles={{ wrapper: { css: tw`px-4` } }}
                        />
                      </div>
                    ))}
                  </div>
                }
              />
            )}
            {basket.price && (
              <PriceWrapper
                beforeDiscount={basket.price.beforeDiscount}
                afterDiscount={basket.price.afterDiscount}
                styles={{
                  wrapper: {
                    css: tw`ml-auto text-lg font-semibold lg:text-xl`,
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default OrderDetails;
