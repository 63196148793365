import { useTranslation } from 'next-i18next';

const useDiscountTranslations = () => {
  const { t } = useTranslation();
  const discountTranslations = {
    DIET_LENGTH: t('$*discount.dietLength', 'Długość diety'),
    DISCOUNT_CODE: t('$*discount.code', 'Kod rabatowy'),
    MONEYBOX: t('$*discount.moneybox', 'Punkty skarbonki'),
    PICK_UP_POINT: t('$*discount.pickUpPoint', 'Punkt odbioru'),
    SAME_ADDRESS: t('$*discount.sameAddress', 'Ten sam adres'),
  };

  return discountTranslations;
};
export default useDiscountTranslations;
