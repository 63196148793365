const INPUT_IDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'phone',
  PASSWORD: 'plainPassword',
  REFERRER: 'knowAboutUs',
  REFERRER_TEXT: 'knowAboutUsText',
  MARKETING_AGREEMENT: 'marketingTerms',
  SERVICE_AGREEMENT: 'serviceTerms',
  KEEP_ME_LOGGED_IN: 'keepMeLoggedIn',
} as const;

export default INPUT_IDS;
