const PriceTagIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 2835 2835"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M2537.236 0h-747.741c-142.428 0-276.312 55.454-377.03 156.162L101.35 1467.287C36.005 1532.632.004 1619.486.004 1712.269c0 92.323 36 179.177 101.346 244.522l776.506 776.507c65.356 65.345 152.2 101.346 244.992 101.346 92.324 0 179.178-35.991 244.523-101.346l1311.115-1311.115c100.718-100.718 156.162-234.602 156.162-377.02V297.412C2834.648 133.485 2701.173 0 2537.236 0zm174.127 1045.323c-.04 109.44-42.668 212.324-119.92 289.577L1280.207 2646.134c-83.75 83.74-230.62 84.32-315.058 0l-776.627-776.626-.01-.01c-42.04-41.92-65.215-97.833-65.215-157.698 0-59.397 23.176-115.3 65.225-157.35L1499.638 243.335c77.522-77.412 180.346-120.04 289.857-120.04h747.74c95.998 0 174.118 78.12 174.118 174.117v747.751c0 .05.01.11.01.16zm-527.781-691.668c-163.937 0-297.412 133.484-297.412 297.411 0 163.937 133.475 297.422 297.412 297.422 163.936 0 297.411-133.475 297.411-297.422 0-163.927-133.475-297.411-297.411-297.411zm0 471.539c-95.997 0-174.128-78.121-174.128-174.128 0-95.996 78.131-174.117 174.128-174.117 96.006 0 174.127 78.12 174.127 174.117 0 96.007-78.13 174.128-174.127 174.128z" />
  </svg>
);

export default PriceTagIcon;
