import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/registerInputs';
import {
  selectModuleConfigClientPanel,
  selectModuleLinks,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { useDefaultSchemaYup } from '@utils/yupMethods';

import useGetReferrerOtherOption from './hooks/useGetReferrerOtherOption';

export const initialValues = {
  [INPUT_IDS.FIRST_NAME]: '',
  [INPUT_IDS.LAST_NAME]: '',
  [INPUT_IDS.EMAIL]: '',
  [INPUT_IDS.PHONE]: { number: '', countryCode: '' },
  [INPUT_IDS.PASSWORD]: '',
  [INPUT_IDS.REFERRER]: { label: '', value: '' },
  [INPUT_IDS.REFERRER_TEXT]: '',
  [INPUT_IDS.MARKETING_AGREEMENT]: false,
  [INPUT_IDS.SERVICE_AGREEMENT]: false,
  [INPUT_IDS.KEEP_ME_LOGGED_IN]: false,
};

export const useValidationSchema = () => {
  const { marketingTerms } = useAppConfigSelector(selectModuleLinks);
  const { showKnowAboutUs } =
    useAppConfigSelector(selectModuleConfigClientPanel) ?? {};

  const referrerOtherOption = useGetReferrerOtherOption();

  const {
    stringRequired,
    emailRequired,
    passwordRequired,
    phoneValid,
    booleanRequired,
    isNotEmptyValid,
  } = useDefaultSchemaYup();

  return Yup.object({
    [INPUT_IDS.FIRST_NAME]: stringRequired,
    [INPUT_IDS.LAST_NAME]: stringRequired,
    [INPUT_IDS.EMAIL]: emailRequired,
    [INPUT_IDS.PASSWORD]: passwordRequired,
    [INPUT_IDS.PHONE]: phoneValid,
    [INPUT_IDS.SERVICE_AGREEMENT]: booleanRequired,
    ...(marketingTerms?.enabled
      ? { [INPUT_IDS.MARKETING_AGREEMENT]: Yup.boolean() }
      : {}),
    ...(showKnowAboutUs
      ? {
          [INPUT_IDS.REFERRER]: isNotEmptyValid,
          [INPUT_IDS.REFERRER_TEXT]: Yup.string().when(INPUT_IDS.REFERRER, {
            is: ({ value }) => {
              return value === referrerOtherOption.value;
            },
            then: stringRequired,
          }),
        }
      : {}),
  });
};
