import has from 'lodash/has';

import { sortBy } from './helpers';

export const isApiError = (
  error: unknown
): error is { 'hydra:description': string } => has(error, 'hydra:description');

export const sortByPosition = <T extends { position: number }>(
  collection: T[],
  order?: 'ASC' | 'DESC'
) => sortBy(collection, 'position', order);
