import type { ReactNode } from 'react';
import { css } from '@emotion/react';
import tw, { theme } from 'twin.macro';

type ButtonWrapperProps = {
  children: ReactNode;
  buttonsCount: number;
};

const ButtonWrapper = ({ children, buttonsCount }: ButtonWrapperProps) => {
  return (
    <div
      css={[
        tw`w-full px-1 py-2`,
        css`
          @media (min-width: ${theme`screens.sm`}) {
            width: calc(100% / ${buttonsCount});
          }
        `,
      ]}
    >
      {children}
    </div>
  );
};

export default ButtonWrapper;
