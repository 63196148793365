import { useTranslation } from 'next-i18next';

import BinIcon from '@assets/icons/BinIcon';
import Tooltip from '@components/elements/Tooltip';

const ClearBasketButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip content={t('$*common.clearBasket', 'Wyczyść koszyk')}>
      <div tw="ml-3 text-red-1" onClick={() => onClick()}>
        <BinIcon tw="w-4 cursor-pointer" />
      </div>
    </Tooltip>
  );
};

export default ClearBasketButton;
