import dynamic from 'next/dynamic';

import { useSelector } from 'react-redux';
import { selectNativeAppConfig } from '@store/nativeAppConfig/nativeAppConfig.slice';

import {
  selectAuthMethods,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

import ButtonWrapper from './ButtonWrapper';

type ExternalAuthProps = {
  onAfterLogin: () => void;
  orText: string;
  isRegister?: boolean;
};

const AppleAuth = dynamic(() => import('./AppleAuth'));
const FacebookAuth = dynamic(() => import('./FacebookAuth'));
const GoogleAuth = dynamic(() => import('./GoogleAuth'));

const ExternalAuth = ({
  onAfterLogin = () => {},
  orText = '',
  isRegister = false,
}: ExternalAuthProps) => {
  const { apple, facebook, google } = useAppConfigSelector(selectAuthMethods);
  const enabledAuthMethodsCount = [
    apple?.enabled,
    facebook?.enabled,
    google?.enabled,
  ].filter(Boolean).length;

  const { disableThirdPartyLoginMethods } = useSelector(selectNativeAppConfig);

  if (!enabledAuthMethodsCount || disableThirdPartyLoginMethods) {
    return null;
  }

  return (
    <>
      <div tw="-mx-1 -my-2 flex flex-wrap flex-col items-center justify-center sm:flex-row">
        {apple?.enabled && (
          <ButtonWrapper buttonsCount={enabledAuthMethodsCount}>
            <AppleAuth
              clientId={apple.parameters?.clientId}
              enabled={apple.enabled}
            />
          </ButtonWrapper>
        )}
        {facebook?.enabled && (
          <ButtonWrapper buttonsCount={enabledAuthMethodsCount}>
            <FacebookAuth
              appId={facebook.parameters?.appId}
              enabled={facebook.enabled}
              onAfterLogin={onAfterLogin}
              isRegister={isRegister}
            />
          </ButtonWrapper>
        )}
        {google?.enabled && (
          <ButtonWrapper buttonsCount={enabledAuthMethodsCount}>
            <GoogleAuth
              clientId={google?.parameters?.clientId}
              isGoogleAuthEnabled={google?.enabled}
              onAfterLogin={onAfterLogin}
              isRegister={isRegister}
            />
          </ButtonWrapper>
        )}
      </div>
      <div tw="py-6 text-center">{orText}</div>
    </>
  );
};

ExternalAuth.displayName = 'ExternalAuth';

export default ExternalAuth;
