import { useRouter } from 'next/router';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAuthenticated,
  selectLastAction,
} from '@store/auth/auth.slice';
import { selectors as basketNewOrderSelectors } from '@store/basket/basketNewOrder.slice';
import { setUserProceed } from '@store/orderForm/orderForm.slice';
import isEmpty from 'lodash/isEmpty';

import TAB_SLUGS from '@constants/tabSlugs';
import {
  changeStepAndSetBeforeValid,
  selectTabs,
} from '@features/orderTabs/orderTabsSlice';
import { useAppMode } from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import { getIDfromIRI } from '@services/Api.service';

const NewOrderCreatePageEffects = () => {
  const router = useRouter();
  const params = router.query;
  const dispatch = useDispatch();

  const [isResetTabsAfterParams, setIsResetTabsAfterParams] = useState(false);

  const { isDiet } = useAppMode();
  const { currentStepIndex, steps } = useSelector(selectTabs);

  const {
    basketQuery: { data: basket = {} },
    currentDiet,
    commonSetDiscountCode,
    initBasket,
    isBasketStatic,
    isCompletedCreateBasketParams,
    recheckDiscountCode,
    transformBasketStatus,
    isEmptyBasket,
  } = useBasketMethods();

  const { isRedirectingToConfirm } = useSelector(
    basketNewOrderSelectors.selectBasket
  );

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isReInitializing, setIsReInitializing] = useState(false);
  const [isInitializedBasket, setIsInitializedBasket] = useState(false);

  useEffect(() => {
    if (currentStepIndex > 0) {
      dispatch(setUserProceed());
    }
  }, [currentStepIndex]);

  const oldBasketParams = {
    diet: parseInt(params?.diet) || null,
    variant: parseInt(params?.variant) || null,
    calorific: parseInt(params?.calorific) || null,
    useEcoContainers: params?.ecoContainers === 'true' ? true : false,
    optionChangeMenu: params?.menuPremium === 'true' ? true : false,
  };

  const hasOldBasketParams = useMemo(
    () => Object.values(oldBasketParams).every(value => value !== null),
    []
  );

  useEffect(() => {
    const initAction = async () => {
      const isNormalBasket = !isEmpty(basket.id) || !isEmpty(params?.basketId);
      const isStaticBasket =
        isBasketStatic || hasOldBasketParams || !isNormalBasket;
      const additionalData =
        isDiet && isNormalBasket ? { withDefaultDiet: true } : {};

      const basketProps = {
        basketId: params?.basketId,
        noBasketMode: false,
        ...additionalData,
        ...(isStaticBasket ? { oldBasketParams } : {}),
      };
      const staticBasketProps = {
        noBasketMode: isStaticBasket,
        ...additionalData,
        ...(isStaticBasket ? { oldBasketParams } : {}),
      };

      const initParams = hasOldBasketParams
        ? staticBasketProps
        : basket.id !== params?.basketId
        ? basketProps
        : staticBasketProps;

      initBasket(initParams).then(() => {
        setIsInitializedBasket(true);
      });
    };

    initAction();
  }, []);

  useEffect(() => {
    // Something bad happend and query basket is missing even tho it was already initialized
    if (
      isDiet &&
      isEmpty(basket) &&
      !isBasketStatic &&
      !isReInitializing &&
      isInitializedBasket &&
      currentStepIndex === 0
    ) {
      setIsReInitializing(true);

      const staticBasketProps = {
        noBasketMode: true,
        oldBasketParams,
      };

      initBasket(staticBasketProps)
        .then(() => {
          setIsReInitializing(false);
        })
        .catch(() => {
          setIsReInitializing(false);
        });
    }
  }, [basket]);

  useEffect(() => {
    const referenceCode = params?.reference_code;
    const discountCode = params?.discount_code;
    const code = referenceCode || discountCode;

    if (
      basket?.discountCode?.code !== code &&
      !isEmpty(code) &&
      isInitializedBasket
    ) {
      commonSetDiscountCode(code);
    }
  }, [isInitializedBasket]);

  const lastAction = useSelector(selectLastAction);

  useEffect(() => {
    const activeDiscountCode = basket?.discountCode?.code;

    if (
      lastAction === 'auth/loginWithUserData/fulfilled' &&
      !isEmpty(activeDiscountCode)
    ) {
      recheckDiscountCode(activeDiscountCode);
    }
  }, [lastAction]);

  useEffect(() => {
    const routerStepSlug = params.step ?? TAB_SLUGS.SELECT_DIET;

    const authStepIndex = steps?.findIndex(
      step => step.slug === TAB_SLUGS.AUTHORIZATION
    );
    const slugStepTabIndex = steps?.findIndex(
      step => step.slug === routerStepSlug
    );
    const slugTabIndex = slugStepTabIndex !== -1 ? slugStepTabIndex : 0;

    if ([null, true].includes(isCompletedCreateBasketParams)) {
      // reset steps valid when isn't reseted after visti page
      if (
        !isResetTabsAfterParams &&
        !(!isAuthenticated && authStepIndex === -1) // one of values is false then true
      ) {
        if (slugTabIndex > authStepIndex) {
          let stepToChange;

          if (isAuthenticated) {
            if (isEmptyBasket) {
              stepToChange = 0;
            } else {
              stepToChange = slugTabIndex;
            }
          } else {
            stepToChange = authStepIndex;
          }

          dispatch(changeStepAndSetBeforeValid(stepToChange));
        } else {
          dispatch(changeStepAndSetBeforeValid(slugTabIndex));
        }

        setIsResetTabsAfterParams(true);
      }
    }
  }, [
    isEmptyBasket,
    isAuthenticated,
    isCompletedCreateBasketParams,
    isResetTabsAfterParams,
    params.step,
    steps,
  ]);

  useEffect(() => {
    const stepSlug = steps[currentStepIndex]?.slug;

    if (!isEmpty(stepSlug) && !isRedirectingToConfirm) {
      const hasBasket = !isEmpty(basket.id);
      const routerQuery = JSON.parse(JSON.stringify(params));
      delete routerQuery.step;
      delete routerQuery.basketId;

      if (transformBasketStatus === 'STATIC_TO_NORMAL') {
        delete routerQuery.diet;
        delete routerQuery.variant;
        delete routerQuery.calorific;
      }

      if (!isBasketStatic) {
        delete routerQuery.diet;
        delete routerQuery.variant;
        delete routerQuery.calorific;
        delete routerQuery.ecoContainers;
        delete routerQuery.menuPremium;
      }

      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...routerQuery,
            ...(!isEmpty(stepSlug) ? { step: stepSlug } : {}),
            ...(hasBasket &&
            !['INIT_NORMAL_TO_STATIC', 'NORMAL_TO_STATIC'].includes(
              transformBasketStatus
            )
              ? { basketId: basket.id }
              : {}),

            ...(isBasketStatic
              ? {
                  calorific: getIDfromIRI(currentDiet.calorificId),
                  diet: getIDfromIRI(currentDiet.dietId),
                  ecoContainers: currentDiet.useEcoContainers,
                  variant: getIDfromIRI(currentDiet.variantId),
                  menuPremium: currentDiet.optionChangeMenu,
                }
              : {}),
          },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [
    currentStepIndex,
    basket.id,
    isBasketStatic,
    currentDiet.calorificId,
    currentDiet.dietId,
    currentDiet.useEcoContainers,
    currentDiet.variantId,
    currentDiet.optionChangeMenu,
  ]);

  return null;
};

export default NewOrderCreatePageEffects;
