import { useTranslation } from 'next-i18next';

import { createContext } from 'react';

import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

export const ClientPanelConfigContext = createContext([{}, () => {}]);

const ClientPanelConfigProvider = ({ children }) => {
  const { t } = useTranslation();

  const { isOneTimePayPaymentMode, isSubscriptionPaymentMode } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const getPaymentModeOptions = () => {
    let paymentModeOptions = [];

    if (isSubscriptionPaymentMode) {
      paymentModeOptions.push({
        label: t('$*common.paymentMode.subscription'),
        value: 1,
        code: BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT,
      });
    }

    if (isOneTimePayPaymentMode) {
      paymentModeOptions.push({
        label: t('$*common.paymentMode.singlePayment', 'Jednorazowe'),
        value: 2,
        code: BASKET_PAYMENT_MODES.SINGLE_PAYMENT,
      });
    }

    return paymentModeOptions;
  };

  const transformBasketFormatToSelectOption = paymentMode => {
    switch (paymentMode) {
      case BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT:
        return {
          label: t('$*common.paymentMode.subscription'),
          value: 1,
        };
      case BASKET_PAYMENT_MODES.SINGLE_PAYMENT:
        return {
          label: t('$*common.paymentMode.singlePayment', 'Jednorazowe'),
          value: 2,
        };
      default: {
        return {};
      }
    }
  };

  const value = {
    // isSubscription,
    // defaultPaymentMode: isDefaultPaymentModeValid() ? defaultPaymentMode : 2,
    getPaymentModeOptions,
    transformBasketFormatToSelectOption,
  };

  return (
    <ClientPanelConfigContext.Provider value={value}>
      {children}
    </ClientPanelConfigContext.Provider>
  );
};

export default ClientPanelConfigProvider;
