import { useTranslation } from 'next-i18next';

import * as Yup from 'yup';

const useValidateOrder = order => {
  const { t } = useTranslation();

  let schema = Yup.object({
    deliveryType: Yup.string().required(t('$*error.field.required')),
    address: Yup.object()
      .shape({})
      .when('deliveryType', {
        is: 'ADDRESS',
        then: Yup.object({
          buildNumber: Yup.string().required(t('$*error.field.required')),
        }).required('wymagane'),
      }),
    pickUpPoint: Yup.mixed()
      .nullable()
      .when('deliveryType', {
        is: 'PICKUP_POINT',
        then: Yup.object({
          '@id': Yup.string().required(t('$*error.field.required')),
        }).required('wymagane'),
      }),
  });

  return {
    isValid: schema.isValidSync(order),
  };
};

export default useValidateOrder;
