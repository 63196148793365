import { Trans, useTranslation } from 'next-i18next';

import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import tw from 'twin.macro';

import Link from '@components/elements/Link';
import INPUT_IDS from '@constants/inputs/registerInputs';
import {
  selectDictionaries,
  selectModuleConfigClientPanel,
  selectModuleLinks,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useMediaQuery from '@hooks/useMediaQuery';
import { isFalsify } from '@utils/helpers';
import { sortByPosition } from '@utils/helpersTS';
import { up } from '@utils/screens';
import useGetReferrerOtherOption from '@views/Auth/RegisterPage/hooks/useGetReferrerOtherOption';

const useRegisterFields = referrerValue => {
  const { t } = useTranslation();
  const isUpMd = useMediaQuery(up('md'), true);
  const { howKnow = [] } = useAppConfigSelector(selectDictionaries);
  const { appTerms, marketingTerms } = useAppConfigSelector(selectModuleLinks);
  const formik = useFormikContext();

  const { showKnowAboutUs } =
    useAppConfigSelector(selectModuleConfigClientPanel) ?? {};

  const referrerOtherOption = useGetReferrerOtherOption();

  const referrerOptions = sortByPosition([...howKnow, referrerOtherOption]).map(
    ({ value }) => ({
      label: value,
      value: value,
    })
  );

  const serviceAgreementRef = useRef(null);
  const marketingAgreementRef = useRef(null);

  const agreementsRefs = [
    marketingAgreementRef.current,
    serviceAgreementRef.current,
  ];

  useEffect(() => {
    agreementsRefs.forEach(agreementRef => {
      if (agreementRef) {
        agreementRef.querySelectorAll('a').forEach(a => {
          a.target = '_blank';
          a.rel = 'noopener noreferrer';
        });
      }
    });
  }, agreementsRefs);

  return [
    {
      colClass: 'sm:col-6',
      id: INPUT_IDS.FIRST_NAME,
      type: 'text',
      label: t('$*input.firstName.label'),
      required: true,
      ...(isUpMd ? { autoFocus: true } : {}),
    },
    {
      colClass: 'sm:col-6',
      id: INPUT_IDS.LAST_NAME,
      type: 'text',
      label: t('$*input.lastName.label'),
      required: true,
    },
    {
      id: INPUT_IDS.EMAIL,
      type: 'text',
      label: t('$*input.email.label'),
      required: true,
      onChange: ({ target }) => {
        const newValue = target.value.toLowerCase();

        formik.setFieldValue(target.name, newValue);
      },
    },
    {
      id: INPUT_IDS.PHONE,
      type: 'phone',
      label: t('$*input.phone.label'),
      required: true,
    },
    {
      id: INPUT_IDS.PASSWORD,
      type: 'password',
      label: t('$*input.password.label'),
      autoComplete: 'new-password',
      required: true,
    },
    ...(showKnowAboutUs
      ? [
          {
            colCss: tw`order-8`,
            id: INPUT_IDS.REFERRER,
            type: 'select',
            label: t('$*input.knowAboutUs.label'),
            options: referrerOptions,
            placeholder: '',
            required: true,
            isSearchable: false,
          },
          ...[
            referrerValue === referrerOtherOption.value && {
              colCss: tw`order-9`,
              id: INPUT_IDS.REFERRER_TEXT,
              type: 'text',
              placeholder: '',
              required: true,
            },
          ].filter(Boolean),
        ]
      : []),
    {
      colCss: tw`order-10`,
      id: INPUT_IDS.SERVICE_AGREEMENT,
      type: 'checkbox',
      label: !isFalsify(appTerms.label) ? (
        <span
          ref={serviceAgreementRef}
          dangerouslySetInnerHTML={{ __html: appTerms.label }}
        />
      ) : (
        <Trans
          i18nKey="$*input.appTerms.label"
          components={[
            <Link
              key="0"
              href={appTerms.link}
              target="_blank"
              rel="noopener noreferrer"
              tw="font-semibold"
            />,
          ]}
        />
      ),
      required: true,
      formGroupProps: { styles: { mode: 'replace', css: tw`my-0.5` } },
    },

    {
      colCss: tw`order-11`,
      id: INPUT_IDS.MARKETING_AGREEMENT,
      type: 'checkbox',
      label: !isFalsify(marketingTerms.label) ? (
        <span
          ref={marketingAgreementRef}
          dangerouslySetInnerHTML={{ __html: marketingTerms.label }}
        />
      ) : (
        <Trans
          i18nKey="$*input.marketingTerms.label"
          components={[
            <Link
              key="0"
              href={marketingTerms.link}
              target="_blank"
              rel="noopener noreferrer"
              tw="font-semibold"
            />,
          ]}
        />
      ),
      required: false,
      formGroupProps: { styles: { mode: 'replace', css: tw`my-0.5` } },
    },

    {
      colCss: tw`order-12`,
      id: INPUT_IDS.KEEP_ME_LOGGED_IN,
      type: 'checkbox',
      label: t('$*input.keepMeLoggedIn.label'),
      required: false,
      formGroupProps: { styles: { mode: 'replace', css: tw`my-0.5` } },
    },
  ];
};

export default useRegisterFields;
